<!--
 * @Author: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @Date: 2023-08-30 19:14:35
 * @LastEditors: error: error: git config user.name & please set dead value or install git && error: git config user.email & please set dead value or install git & please set dead value or install git
 * @LastEditTime: 2023-11-08 08:47:59
 * @FilePath: \official_website\src\views\products\Product.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%A
-->
<!-- 产品及解决方案 -->
<template>
  <div id="product">
    <Header></Header>
    <div class="product-content">
      <div class="product-header">
        <img src="@/assets/image/product/header.png" alt="" />
        <p class="title animate__animated animate__fadeInDown">产品及解决方案</p>
      </div>

      <div class="product-box">
        <div
          class="product-list"
          v-for="(items, indexs) in productList"
          :key="indexs"
        >
          <div class="list-header">
            <div class="line"></div>
            <div class="title">{{ items.title }}</div>
          </div>
          <ul class="list-content">
            <li
              class="item"
              v-for="(item, index) in items.children"
              :key="index"
              @click="tapPages(item.path)"
            >
              <!-- <img :src="item.src" alt="" /> -->
              <div class="prod-img" :style="{ 'background-image': `url(${item.src}` }"></div>
              <div class="describe">{{ item.lable }}</div>
            </li>
            <!-- <li class="item">
              <img src="../../assets/image/poduct/reagent-1.png" alt="" />
              <div class="describe">液基细胞学TCT诊断试剂</div>
            </li>
            <li class="item">
              <img src="../../assets/image/poduct/reagent-2.png" alt="" />
              <div class="describe">DNA定量分析诊断试剂</div>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
    
<script>
import Header from "../componets/Header.vue";
import Footer from "../componets/Footer.vue";
export default {
  name: "",
  props: {},
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      productList: [
        {
          title: "解决方案",
          children: [
             {
              src: require("@/assets/image/product/solution3.png"),
              lable: "宫颈癌筛查解决方案",
              path: "/product/tbs",
              prodId: 29
            },
            {
              src: require("@/assets/image/product/solution1.png"),
              lable: "DNA倍体癌症早筛解决方案",
              path: "/product/dna",
              prodId: 28
            },
            {
              src: require("@/assets/image/product/solution4.png"),
              lable: "远程病理诊断解决方案",
              path: "/product/telepathology",
              prodId: 27
            },
            {
              src: require("@/assets/image/product/solution2.png"),
              lable: "血液细胞形态诊断解决方案",
              path: "/product/blood",
              prodId: 26
            },
          ],
        },
        {
          title: "细胞学早筛诊断试剂",
          children: [
            {
              src: require("@/assets/image/product/reagent-1.png"),
              lable: "液基细胞学TCT诊断试剂",
              path: "/product/tctReagent",
              prodId: 25
            },
            {
              src: require("@/assets/image/product/reagent-2.png"),
              lable: "DNA定量分析诊断试剂",
               path: "/product/dnaReagent",
               prodId: 24
            },
          ],
        },
        {
          title: "细胞学制片染色自动化解决方案",
          children: [
            {
              src: require("@/assets/image/product/film-1.png"),
              lable: "液基制片染色一体机",
              path: "/product/pelleter",
              prodId: 23
            },
            {
              src: require("@/assets/image/product/film-2.png"),
              lable: "液基膜式制片机",
              path: "/product/film",
              prodId: 22
            },
            // {
            //   src: require("@/assets/image/product/film-3.png"),
            //   lable: "液基甩片制片机",
            // },
            {
              src: require("@/assets/image/product/film-4.png"),
              lable: "全自动特殊染色机",
              path: "/product/special",
              prodId: 21
            },
          ],
        },
        {
          title: "数字病理切片扫描解决方案",
          children: [
            {
              src: require("@/assets/image/product/digit-1.png"),
              lable: "扫描通量600片",
              path: "/product/gemini",
              prodId: 19
            },
            {
              src: require("@/assets/image/product/digit-2.png"),
              lable: "扫描通量120片",
              path: "/product/gemini",
              prodId: 18
            },
            {
              src: require("@/assets/image/product/digit-3.png"),
              lable: "扫描通量30片",
              path: "/product/gemini",
              prodId: 17
            },
            {
              src: require("@/assets/image/product/digit-4.png"),
              lable: "扫描通量2片",
              path: "/product/gemini",
              prodId: 16
            },
          ],
        },
        {
          title: "骨髓/血液细胞形态学AI扫描分析解决方案",
          children: [
            {
              src: require("@/assets/image/product/blood-1.png"),
              lable: "骨髓/血液细胞形态学AI扫描分析系统",
              path: "/product/marrow",
              prodId: 7
            },
            {
              src: require("@/assets/image/product/blood-2.png"),
              lable: "全自动推片染色机",
              path: "/product/bloodPushing",
              prodId: 6
            },
          ],
        },
        {
          title: "“吉米娜”人工智能辅助诊断系统",
          children: [
            {
              src: require("@/assets/image/product/diagnosis-1.png"),
              lable: "DNA倍体定量分析系统",
              path: "/product/dnaProd",
              prodId: 5
            },
            {
              src: require("@/assets/image/product/diagnosis-2.png"),
              lable: "细胞学TCT人工智能辅助诊断",
              path: "/product/tctProd",
              prodId: 4
            },
            {
              src: require("@/assets/image/product/diagnosis-3.png"),
              lable: "远程病理会诊系统",
              path: "/product/patholog",
              prodId: 3
            },
          ],
        },
      ],
    };
  },
  created() {},
  mounted() {},
  watch: {},
  methods: {
    tapPages(path) {
      this.$router.push(path);
    },
  },
};
</script>
    
<style lang="scss">
#product {
  .product-content {
    position: relative;
    width: 100%;
    padding-top: 5rem;
    .product-header {
      position: relative;
      img {
        width: 100%;
      }
      .title {
        position: absolute;
        left: 8%;
        top: 40%;
        transform: translateY(-50%);
        color: #fff;
        font-size: 2.4rem;
        font-family: "SourceHanSansSC-Medium";
      }
    }
    .product-box {
      padding: 3% 8%;
      background-color: #fff;
      .product-list {
        margin-bottom: 3.75rem;
        .list-header {
          margin-bottom: 1.75rem;
          .line {
            width: 3.75rem;
            height: 0.375rem;
            background: #0b847b;
            margin-bottom: 0.625rem;
          }
          .title {
            font-size: 1.6rem;
            font-family: "SourceHanSansSC-Medium";
          }
        }
        .list-content {
          display: flex;
          .item {
            width: 23.5%;
            margin-right: 2%;
            box-sizing: border-box;
            &:nth-of-type(4n) {
              margin-right: 0;
            }
            &:hover .prod-img {
              // transform: scale(1.05);
              background-size: 110%;
              border-color: #0b847b;
            }
            &:hover .describe {
              color: #0b847b;
            }
            .prod-img {
              width: 100%;
              height: 18rem;
              border: 2px dashed #bababa;
              margin-bottom: 1rem;
              cursor: pointer;
              background-size: contain;
              background-position: center center;
              transition: all 0.8s ease-in-out;
            }
            .describe {
              font-family: "OPlusSans3-Regular";
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
  .footer {
    width: 100%;
    height: 100%;
    background: #f5f5f5;
    padding: 6.25rem 8% 3.125rem;
  }
}
</style>